@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&family=Inter:wght@400;700&display=swap");

body,
.App {
  background-color: #593299; /* Dark purple background color for the whole page */
  color: #eee7fa; /* General text color for the whole page */
  font-family: "Archivo", sans-serif; /* Set the default font for the whole page */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  height: 100%; /* Ensure full height */
  max-width: 100vw;
}

.navbar {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  background-color: #59329980; /* Updated navbar background color to match */
  position: sticky;
  top: 0;
}

.navbar img {
  height: 100px;
}

.navbar .leftSide {
  padding: 0;
  margin-left: -16px;
}

.navbar .rightSide {
  display: flex;
  gap: 20px;
  justify-content: end;
}

.navbar .rightSide a {
  color: white;
  text-decoration: none;
  border-radius: 8px;
  padding: 12px 16px;
}

.navbar .rightSide a:hover {
  background-color: #a07add;
}

.button {
  background-color: #7d4fc7;
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px;
  padding: 12px 16px;
}

.button:hover {
  background-color: #a07add !important;
  color: #ffffff !important;
  border-radius: 8px;
  padding: 12px 16px;
}

.App {
  text-align: left;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .navbar {
    max-width: calc(90vw) !important;
    padding: 0 24px;
  }
  .section {
    padding: 100px 24px;
    display: flex;
    flex-direction: column;
  }
  .grid {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;
  }
  img {
    max-width: 100vw;
  }

  #home {
    padding: 0 24px 10vh;
  }
  .menu {
    display: flex;
    justify-content: flex-end;
  }
  .rightSide {
    display: none !important;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .prizes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }
  .prize {
    padding: 8px 12px 8px;
  }
  .prize p {
    font-size: 16px !important;
  }

  .prize-alt {
    padding: 8px 12px 8px;
  }

  .prize-alt p {
    font-size: 16px !important;
  }

  .prizes-alt {
    gap: 12px;
  }
}

.menu img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 8px;
}

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #4d2a87;
  width: 100%;
  gap: 16px;
  padding: 48px 0px;
}

.menu-list img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .navbar {
    padding: 0 5vw;
  }
  .section {
    padding: 100px 10vw;
  }
  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 2fr;
    align-items: center;
  }
  #home {
    height: 65vh;
    padding: 12vh 10vw 0;
  }
  .menu {
    display: none;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  .prizes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  .prize {
    padding: 16px 24px 16px;
  }

  .prize-alt {
    padding: 24px;
  }
  .prizes-alt {
    gap: 24px;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #ffffff; /* Set color for the event title */
  font-size: 3em;
}

h2 {
  font-size: 24px;
  color: #fff;
}

section#home p {
  margin: 0.25em 0; /* Add space between paragraphs */
}

/* Specific color for the main description text */
p {
  color: #eee7fa;
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.purple {
  color: #cab2f2;
}

button {
  background-color: #7d4fc7;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  border: none;
  padding: 16px 24px;
  margin-top: 1.5em;
  cursor: pointer;
  font-family: "Archivo", sans-serif;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #a07add;
  color: #eee7fa; /* New text color for the button on hover */
  cursor: pointer;
}

/* Smaller and specific color for the application deadline */
section#home p:last-child {
  color: #cab2f2;
  font-family: "Archivo", sans-serif;
  font-size: 1em; /* Smaller font size */
  font-style: italic;
  margin-top: 2em;
}

.card {
  padding: 24px 24px 16px;
  background-color: #6940ad;
  border-radius: 8px;
}

.prize {
  background-color: #6940ad;
  border-radius: 8px;
}

.prize-alt {
  border: 2px solid #7d4fc7;
  border-radius: 8px;
}

.prizes-alt {
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.prize p,
.prize h2,
.prize-alt p,
.prize-alt h2 {
  margin: 8px 0;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.footer {
  background-color: #4d2a87;
  padding-top: 60px;
}

#partners img {
  width: 100%;
  margin-right: 24px;
  margin-top: 20px;
}
